import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layouts/Layout';
import SEO from '../components/seo/SEO';
import styled from 'styled-components';
import { modularScale } from '../util/modularScale';
import { Formatter } from '../styles/Markdown.styles';

const Container = styled.div`
	position: relative;
	padding: 1rem;
	width: 100%;
	max-width: 700px;
	margin: 0 auto 6rem;
	background-color: #fff;
	
	@media (min-width: 900px) {
	    padding: 2rem;
	}
	
	h1 {
	    font-weight: bold;
        margin-bottom: 1.5rem;
	}
`;


interface Props {
    data: any;
}

const Legal: React.FC<Props> = ({ data }) => {
    const { html, frontmatter } = data.markdownRemark;

    return (
        <Layout customSEO>
            <SEO title={frontmatter.title}/>
            <Container>
                <h1>{frontmatter.title}</h1>
                <Formatter dangerouslySetInnerHTML={{ __html: html }} />
            </Container>
        </Layout>
    );
};

export default Legal;

export const pageQuery = graphql`
    query($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                slug
                title
            }
        }
    }
`;
